<template>
  <div class="py-4">
    <a-spin :spinning="loading">
      <div v-if="echartsShow" class="slideer-button" :style="backCss">
        <a-tooltip transitionName="" placement="left">
          <template slot="title">
            <span>操作小tips：<br />单击鼠标左键查看对应术语关联的数据<br />单击右键查看术语详情</span>
          </template>
          <a-button style="margin-bottom:5px" shape="circle" size="small" icon="question"></a-button>
        </a-tooltip>
        <a-tooltip transitionName="" placement="left">
          <template slot="title">
            <span>刷新</span>
          </template>
          <a-button
            @click="getAllChartData"
            style="margin-bottom:5px"
            shape="circle"
            size="small"
            icon="redo"
          ></a-button>
        </a-tooltip>
        <a-tooltip transitionName="" placement="left">
          <template slot="title">
            <span>回到原点</span>
          </template>
          <a-button @click="rePosition" class="resize-button" style="margin-bottom:5px" shape="circle" size="small">
            <div class="resize"></div>
          </a-button>
        </a-tooltip>
        <a-tooltip transitionName="" placement="left">
          <template slot="title">
            <span>放大</span>
          </template>
          <a-button @click="add" style="margin-bottom:5px" shape="circle" size="small" icon="plus"></a-button>
        </a-tooltip>
        <a-slider style="z-index:1" vertical v-model="sliderNum" :max="200" @change="sliderChange" :min="10" />
        <a-tooltip transitionName="" placement="left">
          <template slot="title">
            <span>缩小</span>
          </template>
          <a-button
            :disabled="scaleNum < 0.2"
            @click="sub"
            style="margin-top:5px"
            shape="circle"
            size="small"
            icon="minus"
          ></a-button>
        </a-tooltip>
      </div>
      <div class="actions flex jc-end mb-4">
        <div style="flex:1">
          <div style="width:800px">
            <a-row type="flex" :gutter="16">
              <a-col>
                <a-input placeholder="请输入原文名或译文名" @pressEnter="searchQuery" v-model="searchText"></a-input>
              </a-col>
              <a-col>
                <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div v-if="echartsShow" @contextmenu.prevent id="box" style="width:100%;height: calc(100vh - 405px) ;"></div>
    </a-spin>
    <terms-modal
      :productionId="parent.id"
      :parent="parent"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="false"
      @closed="drawerClosed"
    ></terms-modal>
    <a-modal v-model="visible" :title="teamData.name" :width="450">
      <div style="display:flex;flex-wrap:wrap;">
        <div
          v-for="item in teamData.lowers"
          :key="item.id"
          @click="openTermModal(item.id)"
          style="cursor: pointer;width:70px;text-align:center;margin-top:15px"
        >
          <img :src="item.subjectImgUrl" style="width:40px;height:40px;border-radius:50%" alt="" />
          <div style="margin-top:5px;">{{ item.processedName }}</div>
          <div v-if="item.fromOtherInfo" style="margin-top:3px;font-size:12px">{{ item.fromOtherInfo.call }}</div>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          关闭
        </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          编辑团队
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import TermsModal from './terms-modal-new'
import { getAction, postAction } from '../../../api/manage'
import { BackgroundColor } from '@icon-park/vue'
import { simpleDebounce } from '@/utils/util'
export default {
  components: {
    TermsModal
  },
  props: {
    parent: {
      type: Object,
      default: {}
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      echartsShow: true,
      teamData: {},
      chartData: [],
      chartLink: [],
      oldChartData: [],
      oldChartLink: [],
      drawerShow: false,
      curRow: {},
      nodeColor: 'block',
      searchText: '',
      visible: false,
      emphasis: false,
      selectData: null,
      scaleNum: 1,
      sliderNum: 100,
      width: 1,
      loading: false,
      color: ''
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color
      }
    }
  },
  mounted() {
    this.getChartData()
  },
  methods: {
    searchQuery() {
      this.getChartData()
    },
    sliderChange(e) {
      this.scaleNum = (e / 100).toFixed(2)
      this.setecharts()
    },
    add() {
      this.scaleNum = this.scaleNum + 0.1
      this.sliderNum = (this.scaleNum * 100).toFixed(0) - 0
      this.setecharts()
    },
    sub() {
      this.scaleNum = this.scaleNum - 0.1
      this.sliderNum = (this.scaleNum * 100).toFixed(0) - 0
      this.setecharts()
    },
    drawerClosed(type) {
      this.drawerShow = false
      /* this.getChartData() */
      //陈立说编辑以后不更新数据，让用户自己刷新
    },
    handleCancel() {
      this.visible = false
      /* this.getChartData() */
    },
    rePosition() {
      this.scaleNum = 1
      this.sliderNum = 100
      this.setecharts()
    },
    getAllChartData() {
      this.selectData = null
      this.oldChartData = []
      this.oldChartLink = []
      this.chartData = []
      this.chartLink = []
      this.width = 1
      this.getChartData()
    },
    async getChartData() {
      this.echartsShow = false
      const res = await getAction('/term/relations', {
        productionId: this.parent.productionId,
        searchText: this.searchText
      })
      this.echartsShow = true
      if (res.code == 200) {
        this.chartLink = res.data.links.map(item => {
          return {
            source: item.source,
            target: item.target,
            /*             value: `${item.name ? item.name + '\n' : ''}${item.call ? item.call + '\n' : ''}${
              item.honorific ? item.honorific + '\n' : ''
            }`, */
            tooltip: {
              show: true,
              formatter: `${item.name ? item.name + '<br />' : ''}${
                item.honorific
                  ? item.honorific != '其他'
                    ? item.honorific + '<br />'
                    : item.elseInfo
                    ? item.honorific + '，' + item.elseInfo + '<br />'
                    : item.honorific + '<br />'
                  : ''
              }${item.call ? item.call + '<br />' : ''}`,
              rich: {
                a: {
                  lineHeight: 1
                }
              }
            }
          }
        })
        res.data.data = [
          ...res.data.data.filter(item => item.termType != '团体'),
          ...res.data.data.filter(item => item.termType == '团体')
        ]
        let data1 = res.data.data.filter(item => item.termTypeName == '主角')
        let data2 = res.data.data.filter(
          item => item.termTypeName == '配角' || item.termTypeName == '路人' || item.termTypeName == '团体'
        )
        this.chartData = [
          ...data1.map((ele, index) => {
            const c = parseInt(index / 4)
            const r = parseInt(index % 4)
            const isOdd = c % 2
            const xnum = 300
            const ynum = 300
            /*             if (isOdd) {
              if (r == 0) {
                ele.x = -xnum + xnum * c
                ele.y = -ynum - ynum * c + 150
              } else if (r == 1) {
                ele.x = xnum + xnum * c
                ele.y = -ynum + ynum * c
              } else if (r == 2) {
                ele.x = xnum - xnum * c
                ele.y = ynum + ynum * c - 150
              } else if (r == 3) {
                ele.x = -xnum - xnum * c
                ele.y = ynum - ynum * c
              }
            } else {
              if (c) {
                if (r == 0) {
                  ele.x = -xnum * c
                  ele.y = -ynum * c
                } else if (r == 1) {
                  ele.x = xnum * c
                  ele.y = -ynum * c
                } else if (r == 2) {
                  ele.x = xnum * c
                  ele.y = ynum * c
                } else if (r == 3) {
                  ele.x = -xnum * c
                  ele.y = ynum * c
                }
              } else {
                if (r == 0) {
                  ele.x = -xnum
                  ele.y = -ynum - 100
                } else if (r == 1) {
                  ele.x = xnum
                  ele.y = -ynum - 100
                } else if (r == 2) {
                  ele.x = xnum
                  ele.y = ynum + 100
                } else if (r == 3) {
                  ele.x = -xnum
                  ele.y = ynum + 100
                }
              }
            } */
            let width = 50
            let height = 50
            let itemStyle = {}
            if (!ele.subjectImgUrl && ele.lowers && ele.lowers.length) {
              height = 120
              width = 120
            }
            if (ele.termTypeName == '主角') {
              width = 70
              height = 70
            }
            return {
              id: ele.id - 0,
              value: ele.id - 0,
              x: ele.x,
              y: ele.y,
              termType: ele.termType,
              /* symbol: `image://${ele.subjectImgUrl}`, */
              symbol: ele.subjectImgUrl || require('@/assets/tx.png'),
              symbolSize: [width, height],
              itemStyle,
              name: ele.processedName,
              termTypeName: ele.termTypeName,
              lowers: ele.lowers,
              gender: ele.gender,
              /*               tooltip: {
                show: false
              }, */
              tooltip: {
                formatter:
                  ele.termType == '团体'
                    ? `<div style='width:200px;padding-left:10px'>
                    <div style='display: flex;line-height:22px;padding:5px 0px;' >
                <div style='width:50%;font-weight:700'>分类：</div>
                <div style='width:45%;'>${ele.termTypeName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>原文名：</div>
                <div style='width:45%;white-space: pre-line;word-wrap: break-word;'>${ele.originName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>译文名：</div>
                <div style='width:45%;'>${ele.processedName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;'>
                <div style='width:50%;font-weight:700'>初次登场章节：</div>
                <div style='width:45%;'>${ele.debut}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>说明：</div>
                <div style='width:45%;white-space: wrap;'>${ele.description || ''}</div>
              </div>
              </div>`
                    : `
              <div style='width:250px;padding-left:10px'>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>原文名：</div>
                <div style='width:45%;white-space: pre-line;word-wrap: break-word;'>${ele.originName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>译文名：</div>
                <div style='width:45%;'>${ele.processedName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>自称：</div>
                <div style='width:45%;'>${ele.call}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>语气：</div>
                <div style='width:45%;'>${ele.tone || ''}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>略称（小名）：</div>
                <div style='width:45%;'>${ele.originNickname}</div>
              </div>
             <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>身份（职业）：</div>
                <div style='width:45%;white-space: wrap;'>${ele.identity}</div>
              </div>
             <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>说明：</div>
                <div style='width:45%;white-space: wrap;'>${ele.description || ''}</div>
              </div>

              </div>

              `
              }
            }
          }),
          ...data2.map((ele, index) => {
            const c = parseInt(index / 4)
            const r = parseInt(index % 4)
            const isOdd = c % 2
            const xnum = 1800
            const ynum = 600
            /*             if (isOdd && c < 3) {
              if (r == 0) {
                ele.x = -xnum + xnum * c
                ele.y = -ynum - ynum * c
              } else if (r == 1) {
                ele.x = xnum + xnum * c
                ele.y = -ynum + ynum * c
              } else if (r == 2) {
                ele.x = xnum - xnum * c
                ele.y = ynum + ynum * c
              } else if (r == 3) {
                ele.x = -xnum - xnum * c
                ele.y = ynum - ynum * c
              }
            } else if (isOdd && c == 3) {
              if (r == 0) {
                ele.x = -xnum
                ele.y = -ynum * c
              } else if (r == 1) {
                ele.x = xnum
                ele.y = -ynum * c
              } else if (r == 2) {
                ele.x = xnum
                ele.y = ynum * c
              } else if (r == 3) {
                ele.x = -xnum
                ele.y = ynum * c
              }
            } else if (c < 4) {
              if (c) {
                if (r == 0) {
                  ele.x = -xnum * c
                  ele.y = -ynum * c
                } else if (r == 1) {
                  ele.x = xnum * c
                  ele.y = -ynum * c
                } else if (r == 2) {
                  ele.x = xnum * c
                  ele.y = ynum * c
                } else if (r == 3) {
                  ele.x = -xnum * c
                  ele.y = ynum * c
                }
              } else {
                if (r == 0) {
                  ele.x = -xnum
                  ele.y = -ynum
                } else if (r == 1) {
                  ele.x = xnum
                  ele.y = -ynum
                } else if (r == 2) {
                  ele.x = xnum
                  ele.y = ynum
                } else if (r == 3) {
                  ele.x = -xnum
                  ele.y = ynum
                }
              }
            } else if (c == 4) {
              if (r == 0) {
                ele.x = -xnum + xnum * c
                ele.y = 0
              } else if (r == 1) {
                ele.x = 0
                ele.y = ynum * c
              } else if (r == 2) {
                ele.x = xnum - xnum * c
                ele.y = 0
              } else if (r == 3) {
                ele.x = 0
                ele.y = -ynum * c
              }
            } else {
              if (r == 0) {
                ele.x = -xnum * c
                ele.y = -ynum * c
              } else if (r == 1) {
                ele.x = xnum * c
                ele.y = -ynum * c
              } else if (r == 2) {
                ele.x = xnum * c
                ele.y = ynum * c
              } else if (r == 3) {
                ele.x = -xnum * c
                ele.y = ynum * c
              }
            } */
            let width = 50
            let height = 50
            let itemStyle = {}
            if (!ele.subjectImgUrl && ele.lowers && ele.lowers.length) {
              height = 120
              width = 120
            }
            if (ele.termTypeName == '主角') {
              width = 70
              height = 70
            }
            return {
              id: ele.id - 0,
              value: ele.id - 0,
              x: ele.x,
              y: ele.y,
              termType: ele.termType,
              /* symbol: `image://${ele.subjectImgUrl}`, */
              symbol: ele.subjectImgUrl || require('@/assets/tx.png'),
              symbolSize: [width, height],
              itemStyle,
              termTypeName: ele.termTypeName,
              name: ele.processedName,
              lowers: ele.lowers,
              gender: ele.gender,
              tooltip: {
                formatter:
                  ele.termType == '团体'
                    ? `<div style='width:200px;padding-left:10px'>
                    <div style='display: flex;line-height:22px;padding:5px 0px;' >
                <div style='width:50%;font-weight:700'>分类：</div>
                <div style='width:45%;'>${ele.termTypeName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>原文名：</div>
                <div style='width:45%;white-space: pre-line;word-wrap: break-word;'>${ele.originName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>译文名：</div>
                <div style='width:45%;'>${ele.processedName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;'>
                <div style='width:50%;font-weight:700'>初次登场章节：</div>
                <div style='width:45%;'>${ele.debut}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>说明：</div>
                <div style='width:45%;white-space: wrap;'>${ele.description || ''}</div>
              </div>
              </div>`
                    : `
              <div style='width:250px;padding-left:10px'>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>原文名：</div>
                <div style='width:45%;white-space: pre-line;word-wrap: break-word;'>${ele.originName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>译文名：</div>
                <div style='width:45%;'>${ele.processedName}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>自称：</div>
                <div style='width:45%;'>${ele.call}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>语气：</div>
                <div style='width:45%;'>${ele.tone || ''}</div>
              </div>
              <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>略称（小名）：</div>
                <div style='width:45%;'>${ele.originNickname}</div>
              </div>
             <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>身份（职业）：</div>
                <div style='width:45%;white-space: wrap;'>${ele.identity}</div>
              </div>
             <div style='display: flex;line-height:22px;padding:5px 0px;white-space: wrap;'>
                <div style='width:50%;font-weight:700'>说明：</div>
                <div style='width:45%;white-space: wrap;'>${ele.description || ''}</div>
              </div>
              </div>

              `
              }
              /*               tooltip: {
                show: false
              } */
            }
          })
        ]
        /*         this.chartData.forEach((ele, index) => {
          const c = parseInt(index / 10)
          const r = parseInt(index % 10)
          const cc = Math.round(c / 2)
          let tierNum = Math.round(c / 2) * 300
          let oddNum = 0
          const isOdd = c % 2
          const xnum = 200
          const ynum = 200
          if (!isOdd && index) {
            oddNum = 2
          }
                    if (!cc) {
            if (r == 0) {
              ele.x = -xnum
              ele.y = -ynum
            } else if (r == 1) {
              ele.x = xnum
              ele.y = -ynum
            } else if (r == 2) {
              ele.x = xnum
              ele.y = ynum
            } else if (r == 3) {
              ele.x = -xnum
              ele.y = ynum
            }
          } else {
            if (isOdd) {
              if (r == 0) {
                ele.x = 0
                ele.y = -ynum * cc * 2
              } else if (r == 1) {
                ele.x = xnum * cc * 2
                ele.y = 0
              } else if (r == 2) {
                ele.x = 0
                ele.y = ynum * cc * 2
              } else if (r == 3) {
                ele.x = -xnum * cc * 2
                ele.y = 0
              }
            } else {
              if (r == 0) {
                ele.x = -xnum * cc * 2
                ele.y = -ynum * cc * 2
              } else if (r == 1) {
                ele.x = xnum * cc * 2
                ele.y = -ynum * cc * 2
              } else if (r == 2) {
                ele.x = xnum * cc * 2
                ele.y = ynum * cc * 2
              } else if (r == 3) {
                ele.x = -xnum * cc * 2
                ele.y = ynum * cc * 2
              }
            }
          }
        }) */
        console.log(this.chartData, 123)
        /*         {-300 -300} {300 -300}
{300 300} {-300 300}
{0 -600} {600 0}
{0 600} {-600 0}
{-600 -600} {600 -600}
 {600 600} {-600 600}
 {0 -1200} {1200 0}
{0 1200} {-1200 0}*/
        this.pubdata(this.chartData)
      }
    },

    getImgData(imgSrc, data) {
      var fun = function(resolve) {
        const canvas = document.createElement('canvas')
        const contex = canvas.getContext('2d')
        const img = new Image()
        img.crossOrigin = ''

        img.setAttribute('crossOrigin', 'anonymous')
        img.src = imgSrc + '?time=' + new Date().valueOf()
        img.onload = function() {
          //设置图形宽高比例
          let center = {
            x: img.width / 2,
            y: img.height / 2
          }
          var diameter = img.width //半径
          canvas.width = diameter + 4
          canvas.height = diameter + 4
          contex.clearRect(0, 0, diameter, diameter)
          contex.save()
          contex.beginPath()
          let radius = img.width / 2
          contex.arc(radius + 2, radius + 2, radius - 5, 0, 2 * Math.PI) //画出圆
          contex.clip() //裁剪上面的圆形
          contex.drawImage(
            img,
            center.x - radius,
            center.y - radius,
            diameter,
            diameter,
            8,
            8,
            diameter - 10,
            diameter - 10
          ) // 在刚刚裁剪的园上画图
          contex.restore() // 还原状态
          contex.beginPath()
          if (data.gender == '女') {
            contex.strokeStyle = '#FF6680' // 设置边框颜色
          } else if (data.gender == '男') {
            contex.strokeStyle = '#3479F7' // 设置边框颜色
          } else {
            contex.strokeStyle = 'rgba(0,0,0,0.25)'
          }

          // 设置边框线宽
          if (data.termTypeName == '主角') {
            contex.lineWidth = 5
          } else {
            contex.lineWidth = 3
          }
          contex.roundRect(2, 2, img.width, img.width, img.width)
          contex.stroke()
          contex.restore()
          resolve(canvas.toDataURL('image/png', 1))
        }
      }

      var promise = new Promise(fun)

      return promise
    },
    getLowers(lowers) {
      var canvas = document.createElement('canvas')
      canvas.width = 120 // 九个图片的总宽度为600像素
      canvas.height = 120 // 九个图片的总高度为400像素
      let p = []
      lowers.forEach((item, i) => {
        if (i > 8) return
        let p1 = new Promise(resolve => {
          var img = new Image()
          img.setAttribute('crossOrigin', 'anonymous')
          img.src = item.imageUrl + '?time=' + new Date().valueOf()
          img.onload = function() {
            // 在Canvas上绘制每个图片
            var ctx = canvas.getContext('2d')
            // 以下代码将图片裁剪为圆形并绘制到Canvas上
            var radius = 15 // 圆形图片的半径为30像素
            var x = (i % 3) * (radius * 2 + ctx.lineWidth + 5) + 10 // 左右交替排列，加上间距10像素
            var y = Math.floor(i / 3) * (radius * 2 + ctx.lineWidth + 5) + 10 // 上下交替排列，加上间距10像素
            ctx.save()
            ctx.beginPath()
            ctx.arc(x + radius, y + radius, radius, 0, Math.PI * 2)
            ctx.clip() //裁剪上面的圆形
            /* ctx.closePath() */
            ctx.drawImage(img, x, y, radius * 2, radius * 2)
            ctx.restore()
            resolve(true)
          }
        })
        p.push(p1)
      })
      var ctx = canvas.getContext('2d')
      ctx.strokeStyle = 'rgba(0,0,0,0.25)' // 设置边框颜色
      ctx.fillStyle = '#fff'
      ctx.lineWidth = 1 // 设置边框线宽
      ctx.roundRect(0, 0, 120, 120, 10)
      ctx.stroke()
      return Promise.all(p).then(res => {
        try {
          let src = canvas.toDataURL()
          return src
          // to do something...
        } catch (e) {}
      })
    },
    async pubdata(json) {
      this.loading = true
      var androidMap = json
      let that = this
      var picList = [] //获取出全部图片
      for (var i = 0; i < androidMap.length; i++) {
        //把图片路径转成canvas
        if (androidMap[i].termType == '人物') {
          let p = await this.getImgData(androidMap[i].symbol, androidMap[i])
          picList.push(p)
        } else {
          if (androidMap[i].lowers && androidMap[i].lowers.length) {
            const res = await this.getLowers(
              androidMap[i].lowers.map(item => {
                return {
                  w: 100,
                  h: 100,
                  imageUrl:
                    item.subjectImgUrl && item.subjectImgUrl[0] ? item.subjectImgUrl[0] : require('@/assets/tx.png')
                }
              }),
              picList
            )
            picList.push(res)
          } else {
            picList.push('')
          }
        }
      }

      Promise.all(picList).then(images => {
        //取出base64 图片 然后赋值到jsondata中
        for (var i = 0; i < images.length; i++) {
          var img = 'image://' + images[i]
          androidMap[i].symbol = img
        }
        // 把数据设置到Echart中data
        this.loading = false
        this.setecharts()
      })
    },
    setecharts() {
      let myChart = this.$echarts.init(document.getElementById('box'))
      var option
      myChart.showLoading()
      myChart.hideLoading()
      option = {
        tooltip: {
          show: true
        },
        animationDurationUpdate: 900,
        animationEasingUpdate: 'quinticInOut',
        symbolSize: 80,
        series: [
          {
            name: '关系图',
            type: 'graph',
            scaleLimit: { min: this.scaleNum, max: this.scaleNum },
            layout: 'force',
            data: this.chartData,
            edges: this.chartLink,
            left: 'center',
            top: 'center',
            center: ['50%', '50%'],
            force: {
              repulsion: 200,
              edgeLength: 300,
              initLayout: null,
              gravity: 0.1,
              friction: 0.3,
              layoutAnimation: true
            },
            coordinateSystem: 'view',
            draggable: true,
            avoidLabelOverlap: true,
            legendHoverLink: true,
            tooltip: {
              show: true
            },
            /* autoCurveness:false, */
            roam: true,
            zoom: 1,
            z: 2,
            label: {
              show: true,
              position: 'bottom',
              formatter: '{b}'
            },
            lineStyle: {
              color: this.$store.state.app.color,
              curveness: 0.1,
              width: this.width
            },
            /* this.hexToRgb(this.$store.state.app.color).rgb  */
            edgeLabel: {
              show: false,
              fontSize: 12,
              tooltip: {
                show: true,
                formatter: '{a|{@score}}',
                rich: {
                  a: {
                    lineHeight: 1
                  }
                }
              },
              color: 'block'
              /*               formatter: '{a|{@score}}',
              rich: {
                a: {
                  lineHeight: 1
                }
              } */
            },
            emphasis: {
              disabled: true,
              focus: 'adjacency',
              /*               label: {
                show: true,
                position: 'bottom',
                formatter: '{b}'
              }, */
              lineStyle: {
                color: this.hexToRgb(this.$store.state.app.color).rgb,
                width: 10
              },
              edgeLabel: {
                show: false,
                fontSize: 12,
                tooltip: {
                  show: false
                },
                color: 'block'
                /* formatter: '{a|{@score}}', */
                /*                 rich: {
                  a: {
                    lineHeight: 1
                  }
                } */
              }
            },
            selectedMode: true,
            select: {
              focus: 'adjacency',
              lineStyle: {
                color: this.hexToRgb(this.$store.state.app.color).rgb,
                width: 10
              },
              edgeLabel: {
                show: false,
                fontSize: 12,
                tooltip: {
                  show: false
                },
                color: 'black'
                /* formatter: '{a|{@score}}', */
                /*                 rich: {
                  a: {
                    lineHeight: 1
                  }
                } */
              }
            },
            edgeSymbol: ['none', 'arrow'],
            edgeSymbolSize: [0, 10]
          }
        ]
      }
      myChart.setOption(option)
      myChart.on('contextmenu', this.nodeDBClick)
      myChart.on('click', this.nodeClick)
      /*       myChart.on('mousemove', this.nodeMoveSim)
      myChart.on('mouseout', this.nodeOutSim) */
    },
    hexToRgb: function(val) {
      //HEX十六进制颜色值转换为RGB(A)颜色值
      // 16进制颜色值的正则
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      // 把颜色值变成小写
      var color = val.toLowerCase()
      var result = ''
      if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
          var colorNew = '#'
          for (var i = 1; i < 4; i += 1) {
            colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1))
          }
          color = colorNew
        }
        // 处理六位的颜色值，转为RGB
        var colorChange = []
        for (var i = 1; i < 7; i += 2) {
          colorChange.push(parseInt('0x' + color.slice(i, i + 2)))
        }
        result = 'rgba(' + colorChange.join(',') + ',0.5)'
        return { rgb: result, r: colorChange[0], g: colorChange[1], b: colorChange[2] }
      } else {
        result = '无效'
        return { rgb: result }
      }
    },
    nodeMove: simpleDebounce(async function(e) {
      this.nodeMoveSim(e)
    }, 100),
    nodeOut: simpleDebounce(async function(e) {
      this.nodeOutSim(e)
    }, 100),
    nodeMoveSim(e) {
      if (e.data.source) {
        this.emphasis = true
      } else {
        this.nodeColor = 'black'
      }
      this.nodeColor = 'black'
      this.setecharts()
    },
    nodeOutSim(e) {
      this.emphasis = false
      this.nodeColor = '#fff'
      this.setecharts()
    },
    async nodeDBClick(e) {
      if (e.dataType == 'edge') return
      if (e.data.termType != '团体') {
        const res = await getAction('/term/detail?id=' + e.data.id)
        if (res.code == 200) {
          this.curRow = res.data
          this.drawerShow = true
        } else {
          this.$message.error(res.msg)
        }
      } else {
        this.openModal(e.data)
      }
    },
    async nodeClick(e) {
      if (e.dataType == 'edge') return
      if (e.data.termType == '团体') return
      if (!this.selectData) {
        this.selectData = e.data
        let newChartLink = this.chartLink.filter(
          item => item.source == this.selectData.id || item.target == this.selectData.id
        )
        if (!newChartLink.length) {
          this.selectData = null
          return
        }
        let newChartData = []
        this.chartData.forEach(item => {
          if (newChartLink.some(i => i.source == item.id || i.target == item.id)) {
            newChartData.push(item)
          }
        })
        this.oldChartData = JSON.parse(JSON.stringify(this.chartData))
        this.oldChartLink = JSON.parse(JSON.stringify(this.chartLink))
        this.chartData = newChartData
        this.chartLink = newChartLink
        this.width = 4
        this.setecharts()
      } else {
        this.selectData = null
        this.chartData = this.oldChartData
        this.chartLink = this.oldChartLink
        this.width = 1
        this.setecharts()
      }
    },
    async handleOk() {
      this.visible = false
      const res = await getAction('/term/detail?id=' + this.teamData.id)
      if (res.code == 200) {
        this.curRow = res.data
        this.drawerShow = true
      } else {
        this.$message.error(res.msg)
      }
    },
    async openTermModal(id) {
      this.visible = false
      const res = await getAction('/term/detail?id=' + id)
      if (res.code == 200) {
        this.curRow = res.data
        this.drawerShow = true
      } else {
        this.$message.error(res.msg)
      }
    },
    openModal(data) {
      this.visible = true
      this.teamData = data
    }
  }
}
</script>

<style scoped lang="less">
.resize {
  width: 12px;
  margin: auto;
  height: 12px;
  transition: 0.3s;
  border-radius: 50%;
  background: gray;
}
.resize-button {
  &:hover {
    .resize {
      opacity: 0.6;
      background: var(--theme-color);
    }
  }
}
.slideer-button {
  width: 40px;
  display: flex;
  right: 0;
  bottom: 160px;
  flex-wrap: wrap;
  justify-content: center;
  height: 200px;
  position: absolute;
}
</style>
